
































































































































section {
  main {
    padding: 60px 0;
    width: 1200px;
    margin: 0 auto;

    ::v-deep .container {
      > div {
        > ul {
          > li {
            font-size: 32px !important;
            line-height: 32px !important;
          }
        }
      }
    }

    .el-table {
      margin-top: 50px;

      ::v-deep tbody {
        > tr {
          > td {
            &:nth-child(1) {
              cursor: pointer;

              &:hover {
                color: #d9261d;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px){
  section {
    main{
      width: 100%;
    }
  }
}
